import React, { useContext, useState,useEffect } from 'react';
import { ListGroup, Dropdown,Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Media } from 'react-bootstrap';
import ChatList from "./ChatList";
import {ConfigContext} from "../../../../contexts/ConfigContext";
import useAuth from '../../../../hooks/useAuth';

//import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../../../assets/images/user/avatar-2.jpg';
//import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
//import avatar4 from '../../../../assets/images/user/avatar-4.jpg';
import { ADMIN_URL, CONFIG } from '../../../../config/constant';

const NavRight = () => {
    const configContext = useContext(ConfigContext);
    const { logout } = useAuth();
    const { rtlLayout } = configContext.state;

    const [listOpen, setListOpen] = useState(false);
    const [notification,setNotificationData]=useState(null);

    useEffect( () => {
        let isMounted = true;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CONFIG.token}`
            },
        };
        fetch(ADMIN_URL+'api/notification/getEmployeeNotification/'+CONFIG.user_id, requestOptions).then((response) => response.json())
            .then((content) => {
                if (content.success != null) {
                   if(isMounted){
                    setNotificationData(content.success);
                   }
                }
            });
        isMounted=false;
            
    },[]);
    const handleLogout = async()=>{
        try{
            await logout();
        }catch(e){
            console.log(e);
        }
    }
    const readNotification=(e,type,id)=>{
        e.preventDefault();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CONFIG.token}`
            },
        };
        fetch(ADMIN_URL+'api/notification/'+type+'/'+id, requestOptions).then((response) => response.json())
            .then((content) => {
                if (content.success != null) {
                   fetch(ADMIN_URL+'api/notification/getEmployeeNotification/'+CONFIG.user_id, requestOptions).then((response) => response.json())
                    .then((content) => {
                        if (content.success != null) {
                            setNotificationData(content.success);
                        }
                    });
            
                }
            });
    }
    
    const readAllNotification=(e)=>{
        e.preventDefault();
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CONFIG.token}`
            },
        };
        fetch(ADMIN_URL+'api/notification/updateEmployeeAllTypeNotificationReadStatus/'+CONFIG.user_id, requestOptions).then((response) => response.json())
            .then((content) => {
                if (content.success != null) {
                   fetch(ADMIN_URL+'api/notification/getEmployeeNotification/'+CONFIG.user_id, requestOptions).then((response) => response.json())
                    .then((content) => {
                        if (content.success != null) {
                            setNotificationData(content.success);
                        }
                    });
            
                }
            });
    }
    
    return (
        <React.Fragment>
            <ListGroup as='ul' bsPrefix=' ' className="navbar-nav ml-auto" id='navbar-right'>
                <ListGroup.Item as='li' bsPrefix=' '>
                {notification!=null?
                    <Dropdown alignRight={!rtlLayout}>
                    
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            <i className="feather icon-bell icon" ><Badge variant="danger" className="position-absolute top-0 start-100 translate-middle mt-1 p-1 bg-danger border border-light rounded-circle">{notification.roleNotification.length+notification.allNotification.length+notification.myNotification.length==0?'':notification.roleNotification.length+notification.allNotification.length+notification.myNotification.length}</Badge>
                            </i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="notification notification-scroll">
                            <div className="noti-head">
                                <h6 className="d-inline-block m-b-0">Notifications</h6>
                                <div className="float-right">
                                    <a href='javascript:void(0)' onClick={(event)=>readAllNotification(event)}>clear all</a>
                                </div>
                            </div>
                            <PerfectScrollbar>
                            <ListGroup as='ul' bsPrefix=' ' variant="flush" className="noti-body" >
                            
                                {
                                    notification.roleNotification.length>0?
                                    notification.roleNotification.map((myNotification,i)=>{
                                        return(
                                            <ListGroup.Item as='li' bsPrefix=' '  className="notification">
                                            <Media>
                                            <i className="feather icon-bell icon mr-3" />
                                                <Media.Body>
                                                    <p>
                                                        <strong>{myNotification.title}</strong>
                                                        <span className="n-time text-muted" onClick={(event)=>readNotification(event,'updateEmployeeRoleWiseNotificationReadStatus',myNotification.id)
                                                            
                                                        }>
                                                            <i className="icon feather icon-x-circle m-r-10" style={{fontSize:18}} />
                                                        </span>
                                                    </p>
                                                    <p>{myNotification.content}</p>
                                                </Media.Body>
                                            </Media>
                                        </ListGroup.Item>
                                    
                                        );
                                    })
                                :<></>
                                
                                }
                                {
                                    notification.allNotification.length>0?
                                    notification.allNotification.map((myNotification,i)=>{
                                        return(
                                            <ListGroup.Item as='li' bsPrefix=' '  className="notification">
                                            <Media>
                                            <i className="feather icon-bell icon mr-3" />
                                                <Media.Body>
                                                    <p>
                                                        <strong>{myNotification.title}</strong>
                                                        <span className="n-time text-muted" onClick={(event)=>readNotification(event,'updateEmployeeAllNotificationReadStatus',myNotification.id)}>
                                                            <i className="icon feather icon-x-circle m-r-10" style={{fontSize:18}} />
                                                        </span>
                                                    </p>
                                                    <p>{myNotification.content}</p>
                                                </Media.Body>
                                            </Media>
                                        </ListGroup.Item>
                                    
                                        );
                                    })
                                    :<></>
                                
                                } {
                                    notification.myNotification.length>0?
                                    notification.myNotification.map((myNotification,i)=>{
                                        return(
                                            <ListGroup.Item as='li' bsPrefix=' '  className="notification">
                                            <Media>
                                            <i className="feather icon-bell icon mr-3" />
                                                <Media.Body>
                                                    <p>
                                                        <strong>{myNotification.title}</strong>
                                                        <span className="n-time text-muted" onClick={(event)=>readNotification(event,'updateEmployeeNotificationReadStatus',myNotification.id)}>
                                                            <i className="icon feather icon-x-circle m-r-10" style={{fontSize:18}} />
                                                        </span>
                                                    </p>
                                                    <p>{myNotification.content}</p>
                                                </Media.Body>
                                            </Media>
                                        </ListGroup.Item>
                                    
                                        );
                                    })
                                    :<></>
                                
                                }
                                </ListGroup>
                            </PerfectScrollbar>
                        </Dropdown.Menu>
                        
                    </Dropdown>
                    :<></>}
                </ListGroup.Item>
                {/* <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown>
                        <Dropdown.Toggle as={Link} variant='link' to='#' className="displayChatbox" onClick={() => setListOpen(true)}>
                            <i className="icon feather icon-mail"/>
                        </Dropdown.Toggle>
                    </Dropdown>
                </ListGroup.Item> */}
                <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={!rtlLayout} className="drp-user">
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            <i className="icon feather icon-user"/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                <img src={avatar2} className="img-radius" alt="User Profile"/>
                                <span>{CONFIG.username}</span>
                                <Link to='#' className="dud-logout" title="Logout" onClick={handleLogout}>
                                    <i className="feather icon-log-out"/>
                                </Link>
                            </div>
                            <ListGroup as='ul' bsPrefix=' ' variant='flush' className="pro-body">
                                 {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-settings"/> Settings</Link></ListGroup.Item> */}
                                {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-user"/> Profile</Link></ListGroup.Item> */}
                                {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-mail"/> My Messages</Link></ListGroup.Item> */}
                                {/* <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item"><i className="feather icon-lock"/> Lock Screen</Link></ListGroup.Item>  */}
                                <ListGroup.Item as='li' bsPrefix=' '><Link to='#' className="dropdown-item" onClick={handleLogout}><i className="feather icon-log-out"/> Logout</Link></ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
        </React.Fragment>
    );
};

export default NavRight;
