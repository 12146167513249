import React, { createContext, useEffect, useReducer } from 'react';

  import {  LOGIN_STATE_CHANGED } from "../store/actions";
import Loader from "../components/Loader/Loader";
import { ADMIN_URL } from '../config/constant';

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case LOGIN_STATE_CHANGED: {
      const { isLoggedIn, user } = action.payload;

      return {
        ...state,
        isLoggedIn,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const LoginContext = createContext({
  ...initialState,
  userLogin: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const logout = () => {
    localStorage.removeItem('isUserLogged');
    dispatch({
      type: LOGIN_STATE_CHANGED,
      payload: {
        isLoggedIn: false,
        user: null,
      }
    });
  };
 const userLogin = async (username,password)=>{
      return new Promise(async (resolve, reject) => {
        const requestOptions = {
          method: 'POST',
           headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify({ username: username, password: password })
        };
        const response = await fetch(ADMIN_URL+'api/login', requestOptions)
        if (response.status === 200) {
          let responseData=await response.json();
          localStorage.setItem('isUserLogged',JSON.stringify({
            'isUserLogged':true,
            'user':{
              'username':responseData.success.username,
              'role':responseData.success.role,
              'id':responseData.success.userid,
              'token':responseData.success.token,
            }
          }));
          dispatch({
            type: LOGIN_STATE_CHANGED,
            payload: {
              isLoggedIn: true,
              user: {
                username: responseData.success.username,
                role:responseData.success.role,
                id:responseData.success.userid,
                token:responseData.success.token,
              }
            }
          });
          resolve('success');
        } else {
          reject("not sucess");
        }
     });
    }
  useEffect(() => {
    let user=JSON.parse(localStorage.getItem('isUserLogged'));
      if (user) {
        dispatch({
          type: LOGIN_STATE_CHANGED,
          payload: {
            isLoggedIn: true,
            user: {
              username: user.user.id,
              role:user.user.role,
              id:user.user.id,
              token:user.token,
            }
          }
        });
      } else {
        dispatch({
          type: LOGIN_STATE_CHANGED,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
  }, [dispatch]);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
      <LoginContext.Provider value={{ ...state,  userLogin, logout }}>
        {children}
      </LoginContext.Provider>
  );
};

export default LoginContext;
