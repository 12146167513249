import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/actions";
import colorLogo from '../../../../assets/images/website-frontend/SW-Logo-icon-Color.webp';

const NavLogo = () => {
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const { dispatch } = configContext;


    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
        <React.Fragment>
            <div className="navbar-brand header-logo">
                 <Link to='#' className="b-brand">
                    {/* <div className="b-bg"> */}
                    <img src={colorLogo} alt='Swimwell Logo' width={'30px'} title='Swimwell Logo' />
                        {/* <i className="feather icon-trending-up" /> */}
                    {/* </div> */}
                    <span className="b-title">Managewell</span>
                 </Link>
                <Link to='#' className={toggleClass.join(' ')} id="mobile-collapse" onClick={() => dispatch({type: actionType.COLLAPSE_MENU})}><span /></Link>
            </div>
        </React.Fragment>
    );
};

export default NavLogo;
