import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import $ from 'jquery'
import Header from '../../views/website-frontend/Header/Header';
import Footer from '../../views/website-frontend/Footer/Footer';
import '../../assets/scss/website-frontend/style.scss';
import colorLogo from '../../assets/images/website-frontend/SW-Logo-Color.webp';
import whiteLogo from '../../assets/images/website-frontend/SW-Logo-White.webp';

const App = ({ children }) => {

  // window scroll
  const [offset, setOffset] = useState(0);
  const [logo, setLogo] = useState(whiteLogo);
  const [pageUrl, setPageUrl] = useState("/");

  const windowScroll = () => {

    if (pageUrl !== 'career/assistant-professor'){
      if (offset < 50) {
        setLogo(whiteLogo);
        $('.navbar').addClass('is-transparent');
        // if(this.activeBurger == true){
        //   $('.navbar').removeClass('is-transparent');
        // }
      } else {
        setLogo(colorLogo);
        $('.navbar').removeClass('is-transparent');
      }
    }

  };

  useEffect(() => {

    // setPageUrl(window.location.href.replace('http://localhost:3000/',''));
    // window scroll
    windowScroll()
    window.addEventListener('scroll', function () {
      setOffset(window.pageYOffset)
    });

  });

  return (
    <>
      {/* Dynamic SEO Tags start*/}
      <Helmet>
          {/* Google Tag Manager start */}
          <script charset="utf-8" >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TWN9DFG');`}</script>

          {/* Google tag (gtag.js) */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-129119233-1"></script>
          <script>{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config','UA-129119233-1');`}</script>
          
          <meta name="google-site-verification" content="KS7tyKKr9vLR7Sf0smWXV42nGDs3dbsW9JKuoJOctC8" />

      </Helmet>

      {/* Google Tag Manager (noscript) */}
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TWN9DFG"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      {/* End Google Tag Manager (noscript) */}

      {/* Dynamic SEO Tags end*/}

      <Header offest={offset} logo={logo} />
      {children}
      <Footer />
    </>
  );
}

export default App;